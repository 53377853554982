import React from "react";
import { useLocation } from 'react-router-dom'

import WorkFreeHolding from './WorkFreeHolding'
import WorkFreeOffice from './workFreeOffice'
import WorkFreeProducciones from './WorkFreeProducciones'

import ondainferior from './media/piepag13.svg'
import ondasuperior from './media/header13.svg'
import { useMediaQuery } from 'react-responsive'

function WorkFreeHome(){
    const location = useLocation()
    const isHome = location=='/' ? 1:0
    const isMobile = useMediaQuery({ query: '(max-width: 900px)' })

    return(
    <div className="WorkFreeHome">
        
        <WorkFreeHolding/>
        {!isMobile && <div className="ondasuperior">&nbsp;</div> }
        {
            (!isMobile && location.pathname=="/office") && <div className="ondasuperior"></div>
        }
        <WorkFreeOffice /*path={location}*/ isHome={isHome} />
        {
            (!isMobile && location.pathname=="/office") && <div className="ondainferior"></div>
        }
        {!isMobile &&  <div className="ondainferior">&nbsp;</div> }
      
       
        <WorkFreeProducciones  isHome={isHome} />
        {!isMobile &&<div className="ondasuperior">&nbsp;</div>}
        {!isMobile &&<div className="ondainferior">&nbsp;</div>}
    </div>
    )
}

export default WorkFreeHome