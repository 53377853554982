import React,{ Suspense } from 'react'
import Carousel from 'nuka-carousel'

//TODO use lazy load
import imgMobile_1 from './carousel/imagen-carrusel-1-movil.jpg'
import imgMobile_2 from './carousel/imagen-carrusel-2-movil.jpg'
import imgMobile_3 from './carousel/imagen-carrusel-3-movil.jpg'

    
function WorkFreeProduccionesEventos_Carousel_Mobile(){
    
    return(
        <Suspense fallback={<div>Cargando carrusel...</div>}>
        <Carousel
            wrapAround
            autoplay
            dragging
            swiping
            withoutControls
            pauseOnHover={false}
            slidesToShow={1}
            autoplayInterval={4000}
        >
            <img src={imgMobile_1} className="carousel_mobile_img"/>
            <img src={imgMobile_2}  className="carousel_mobile_img"/>
            <img src={imgMobile_3}  className="carousel_mobile_img"/>
        </Carousel>
        </Suspense>
    )
}

export default WorkFreeProduccionesEventos_Carousel_Mobile