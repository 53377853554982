import React from 'react'
import styled from 'styled-components';
import { useLocation } from 'react-router-dom'

import { FaBars } from 'react-icons/fa';

const Toggle = ({handleNavToggle}) => {
    const location = useLocation()
    const isSitemap = location=='/sitemap' ? 1:0
    return (!isSitemap &&
        <StyledToggle className="animate__animated animate__fadeInRight" onClick={handleNavToggle}>
            Menú
            <FaBars/>
        </StyledToggle>
    )
}

const StyledToggle = styled.button`
    z-index:1000;
    position: fixed;
    right: 20px;
    top:30px;
    color: white;
    background: #eee;
    /**padding: .75rem;**/
    display: flex;
    place-items: center;
    font-size: 2rem;
    cursor: pointer;
    border: none;
    background-color: transparent;
`;

export default Toggle
