import React,{Suspense,useState} from 'react'
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { useMediaQuery } from 'react-responsive'
import { useLocation } from 'react-router-dom'

import {FaLongArrowAltRight} from 'react-icons/fa'

import Header from './header'
import WorkFreePv from './WorkFreePv';
import WorkFreeFooter from './foooter';

//TODO Improve
import Menu from './menu/Menu'
import Toggle from './menu/Toggle';

import  ScrollToTop from './ScrollToTop'
//TODO move to own file
function WorkFreeMobileMenu(){
    const [navToggled, setNavToggled] = useState(false);

    const handleNavToggle = () => {
      setNavToggled(!navToggled);
    }
    const isMobile = useMediaQuery({ query: '(max-width: 900px)' })
    
    const location = useLocation()
    const isSitemap = location.pathname=='/sitemap' ? 1:0

    return (!isSitemap &&
        <div className="workfreepv_menu">
        { (isMobile && !navToggled) && <Toggle handleNavToggle={handleNavToggle} className="menutoggle"/>}
        { (isMobile && navToggled) ? <Menu handleNavToggle={handleNavToggle} /> : null }
        </div>
    )
}

//TODO move to own file
function WorkFreePv_VolverArriba(){ 
    const handleClick = () => {
        window.scrollTo(0, 0)
    }
    const location = useLocation()
    const isSitemap = location.pathname=='/sitemap' ? 1:0

    return (!isSitemap &&
        <div className='VolverArriba' onClick={handleClick}>
            Volver Arriba <FaLongArrowAltRight/>
        </div>
    )
}

//TODO move to own file
function WorkFreePvRootComponent(){
    
    return(
        <Suspense fallback={<p>Cargando... espere un momento</p>}>
            <BrowserRouter>
                <ScrollToTop />
                <Header />
                <WorkFreePv/>
                <WorkFreeFooter/>
                <WorkFreeMobileMenu/>
                <WorkFreePv_VolverArriba/>
            </BrowserRouter>
        </Suspense> 
    )
}

const WorkFreePvDiv = document.getElementById('WorkFreePv');
const WorkFreePvRoot = createRoot(WorkFreePvDiv);
WorkFreePvRoot.render(
    <WorkFreePvRootComponent/>
);
