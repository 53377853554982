import React from 'react'

import Header from '../header'

import insta from '../media/rrss/instagramblack2.svg'
import face from '../media/rrss/facebookblack2.svg'

import WorkFreeProduccionesEventos_Carousel from './carousel'
/**
 * OLD LAYOUT
 * <div  className="WorkFreeProducciones_block_2" >
                    <div className="WorkFreeProducciones_block_14 ">
                        Eventos corporativos
                    </div>
                    <div className="WorkFreeProducciones_block_15" >
                        Eventos Privados
                  </div>
                  <div className="WorkFreeProducciones_block_16" >
                        Eventos culturales
                  </div>
                </div>
                <div  className="WorkFreeProducciones_block_2" >
                    <div className="WorkFreeProducciones_block_17">
                        Shows
                    </div>
                    <div className="WorkFreeProducciones_block_18" >
                        Espectáculos
                  </div>
                  <div className="WorkFreeProducciones_block_19" >
                    Catering
                  </div>
                  <div className="WorkFreeProducciones_block_20" >
                        Banqueteria
                  </div>
                </div>

                        <div className="WorkFreeProducciones_block_10"></div>


                         <div className="WorkFreeProducciones_block_6_row asFlex">
                <div className="WorkFreeProducciones_block_12">
                    <img src={evento1} width="180" height="180"   className="WorkFreeProducciones_block_5"/>
                </div><div className="WorkFreeProducciones_block_12">
                    <img src={evento2} width="180" height="180"   className="WorkFreeProducciones_block_5"/>
                </div>
                <div className="WorkFreeProducciones_block_12">
                    <img src={evento3} width="180" height="180"   className="WorkFreeProducciones_block_5"/>
                </div>
            </div>

 */

//TODO Improve
import './eventos_desktop.css'
import './eventos_mobile.css'

function contactus(){
    location.href='/contacto'
}
export default () => (
        <div className="WorkFreeProducciones_eventos">
          
           <div className="WorkFreeProduccionesEventos_title" >
               <h1>EVENTOS</h1>
           </div>
            <div className="WorkFreeProduccionese_eventos_block_1">
                <p>Generamos momentos únicos, inolvidables y efectivos, motivando la participación de las personas, con estrategias creativas y entretenidas. Contamos con un sólido equipo y la experiencia para integrar tecnología, recurso humano, diseño, producción y puesta en escena.</p>
            </div>
            <div className='WorkFreeProducciones_eventos_block_28'>
                <WorkFreeProduccionesEventos_Carousel/>
            </div>
            <div className='WorkFreeProducciones_eventos_block_27'>
                ¡Hacemos que tus eventos sean memorables!
            </div>
            <div className='WorkFreeProducciones_eventos_boton_cotizar' onClick={contactus}>
                Cotiza con nosotros
            </div>
            <div className="WorkFreeProducciones_eventos_block_6"  >
           
                <div className="centerded">Visita nuestras redes sociales - Work free Producciones</div>
                <div className="centerded">
                    <a href="https://instagram.com/workfreepv.cl">
                        <img src={insta} alt="@workfreepv" width="30" height="30" className="WorkFree_RRSS"/>
                    </a>
                    <a href="https://www.facebook.com/workfreepv.cl/">
                        <img src={face}  alt="@workfreepv" width="30" height="30" className="WorkFree_RRSS"/>
                    </a>
                </div>
            </div>
        </div>

)