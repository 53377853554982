import React,{useState} from 'react'
import { useMediaQuery } from 'react-responsive'

import './workfreepv_matrimonios.css'

import matri1 from './image/fotos-matri1.jpg'
import matri2 from './image/fotos-matri2.jpg'
import matri3 from './image/fotos-matri3.jpg'
import matri4 from './image/fotos-matri4.jpg'
import matri5 from './image/fotos-matri5.jpg'
import matri6 from './image/fotos-matri6.jpg'
import matri7 from './image/fotos-matri7.jpg'

const form2mailUrl = 'https://workfreepv.cl/php/form2mail.php'

function WorkFreeMatrimonios(){
   
    const isMobile = useMediaQuery({ query: '(max-width: 900px)' }) //TODO define limit

    const [largo,updateLargo] = useState(0)

    const updateMensajeLenght = ( value) => {
        updateLargo( value.target.value.length);
    }

    const handleSubmit = () => {
        //TODO add validations
        const nombre = document.getElementById('WorkFree_Contacto_Nombre')
        if( nombre.value.length==0){
            alert('Por favor ingresa tu nombre'); //TODO cambiar a modal
            nombre.focus();
            return;
        }
        const phone = document.getElementById('WorkFree_Contacto_Telefono')
        if( phone.value.length==0){
            alert('Por favor ingresa tu teléfono'); //TODO cambiar a modal
            phone.focus();
            return;
        }
        const mail = document.getElementById('WorkFree_Contacto_Correo')
        if( mail.value.length==0){
            alert('Por favor ingresa tu correo'); //TODO cambiar a modal
            mail.focus();
            return;
        }
        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if(!mail.value.match(mailformat)){
            alert('Por favor ingresa un correo válido'); //TODO cambiar a modal
            mail.focus();
            return;
        }

        const fecha = document.getElementById('WorkFree_Contacto_Fecha')
        if( fecha.value.length==0){
            alert('Por favor ingresa la fecha'); //TODO cambiar a modal
            fecha.focus();
            return;
        }
        const invitados = document.getElementById('WorkFree_Contacto_Invitados')
        if( invitados.value.length==0){
            alert('Por favor ingresa la cantidad de invitados'); //TODO cambiar a modal
            invitados.focus();
            return;
        }
        const message = document.getElementById('WorkFree_Contacto_Detalle')
        if( message.value.length==0){
            alert('Por favor ingresa el mensaje'); //TODO cambiar a modal
            message.focus();
            return;
        }
        const formData = {
            ['matrimonio']:1,
            ['name']: nombre.value,
            ['phone']: phone.value,
            ['from']: mail.value,
            ['date']: fecha.value,
            ['guests']: invitados.value,
            ['message']: message.value
        };
        (async()=>{
          const response = await fetch(form2mailUrl, {
                mode: 'cors', 
                method: 'POST',
              //  headers: { 'Content-Type': 'application/json' },//TODO add to cors
                body: JSON.stringify(formData) });
              //  const data = await response.json()
                alert("Correo enviado");
       })()
      }

    return(
    <div>
         <div className="WorkFreeProducciones_Matrimonios">
          
          <div className="WorkFreeProducciones_Matrimonios_title">
              {!isMobile && <h1>Matrimonios "Todo Incluido"</h1> }
              {isMobile && <h1>Matrimonios <br/>"Todo Incluido"</h1> }
          </div>
           <div className="WorkFreeProducciones_Matrimonios_body">
               <div className="WorkFreeProducciones_Matrimonios_body_1">
                   <h2>Centro de eventos</h2>
                   <p>Te ayudamos en la búsqueda de la mejor alternativa, de acuerdo con la necesidad y número de invitados. Incluye la decoración y ambientación del lugar.</p>
                   <h2>Banquetería</h2>
                   <p>Contamos con un servicio de primer nivel y una amplia y exquisita carta gastronómica, que incluye coctel, cena, buffet de postre, Torta de novios, Bar, servicio de trasnoche. Staff de garzones, mantelería y decoración especial para las mesas.</p>
                   <h2>Área técnica</h2>
                   <p>Un completo paquete de servicios que convertirán tu fiesta en una experiencia única de música, luces, imágenes y sorpresas. Contamos con Amplificación, micrófonos inalámbricos, DJ mezclando en vivo, Maestro de ceremonia y Animador, Iluminación decorativa y de fiesta, pantalla gigante, máquina de humo, cuadro para dedicatorias.</p>
                   <h2 className="cursive centered">Flexibilidad de Pago</h2>
               </div>
               <div className="WorkFreeProducciones_Matrimonios_body_2">
                   <div className="WorkFreeProducciones_Matrimonios_body_2_row">
                        <div className="WorkFreeProducciones_Matrimonios_body_2_cell">
                            <img src={matri6} width="80" height="80" className="WorkFreeProducciones_Matrimonios_body_img"/>
                        </div>
                        <div className="WorkFreeProducciones_Matrimonios_body_2_cell">
                            <img src={matri2} width="80" height="80" className="WorkFreeProducciones_Matrimonios_body_img"/>
                        </div>
                   </div>
                   <div className="WorkFreeProducciones_Matrimonios_body_2_row">
                        <div className="WorkFreeProducciones_Matrimonios_body_2_cell">
                            <img src={matri1} width="80" height="80" className="WorkFreeProducciones_Matrimonios_body_img"/>
                        </div>
                        <div className="WorkFreeProducciones_Matrimonios_body_2_cell">
                            <img src={matri7} width="80" height="80" className="WorkFreeProducciones_Matrimonios_body_img"/>
                        </div>
                    </div>
               </div>
           </div>
       </div>
       {
        (location.pathname=="/matromonios") && <div className="ondasuperior"></div>
        }
       <div className="WorkFreeProducciones_Matrimonios_body_3" >
           <div className="detallesQueMarcanDiferencia">
               Detalles que marcan la diferencia
           </div>
               <div className="WorkFreeProducciones_Matrimonios_body_4">
                   <div className="WorkFreeProducciones_Matrimonios_body_5">
                       <p>Partes de matrimonio y recuerdos</p> 
                       <p>Auto de novios ( De colección ) </p>
                       <p>Cotillón</p>
                       <p>Hotel para noche de bodas</p>
                       <h2>“Actividades opcionales”</h2>
                       <p className="cursive">Para disfrutar de una jornada inolvidable</p>
                       <p>Show de juegos y baile entretenido, cantantes, bandas, magia, batucada, saxofonista, personajes y un sin fin de alternativas para eventos.</p>
                   </div>
                   
                   <div className="WorkFreeProducciones_Matrimonios_body_6">
                       <img src={matri5} width="80" height="80" className="WorkFreeProducciones_Matrimonios_body_img"/>
                       <img src={matri4} width="80" height="80" className="WorkFreeProducciones_Matrimonios_body_img"/>
                       <img src={matri3} width="80" height="80" className="WorkFreeProducciones_Matrimonios_body_img"/>
                   </div>
                   
               </div>
       </div>
       <div className="WorkFreeProducciones_Matrimonios_contact" >
           <div className="WorkFree_Contact_Header">¿Quieres obtener mayor información?</div>
           <div className="WorkFree_Contact_Header_1">
                <div className="WorkFree_contact_field_matri">
                    <div className="WorkFree_contact_field_matri_label_matri">Nombre y apellido</div>
                    <div><input type="text" id="WorkFree_Contacto_Nombre"  className='contacto_field'/></div>
                </div>
                <div className="WorkFree_contact_field_matri">
                    <div className="WorkFree_contact_field_matri_label_matri">Correo</div>
                    <div><input type="text"  id="WorkFree_Contacto_Correo" className='contacto_field'/></div>
                </div>
                <div className="WorkFree_contact_field_matri">
                    <div className="WorkFree_contact_field_matri_label_matri">Teléfono</div>
                    <div><input type="text"  id="WorkFree_Contacto_Telefono" className='contacto_field'/></div>
                </div>
                <div className="WorkFree_contact_field_matri">
                    <div className="WorkFree_contact_field_matri_label_matri">Fecha del evento</div>
                    <div><input type="text"  id="WorkFree_Contacto_Fecha" className='contacto_field'/></div>
                </div>
                <div className="WorkFree_contact_field_matri">
                    <div className="WorkFree_contact_field_matri_label_matri">Nº de invitados</div>
                    <div><input type="text"  id="WorkFree_Contacto_Invitados" className='contacto_field'/></div>
                </div>
                <div className="WorkFree_contact_field_matri">
                    <div className="WorkFree_contact_field_matri_label_matri">Detalle  <span className='largomensaje'>({largo}/200)</span></div>
                    <div><textarea name="detalle" id="WorkFree_Contacto_Detalle" className="textAreaMatrimonio" cols="16" rows="5" maxLength="200"  onChange={updateMensajeLenght}></textarea></div>
                </div>
                <div >
                    <button id="WorkFree_contact_button" className="WorkFree_contact_button" onClick={handleSubmit}>Enviar</button>
                </div>
           </div>
       </div>
    </div>
)}

export default WorkFreeMatrimonios