import React,{useState} from 'react' 

import Header from './header'
import Mapa from './media/contacto/mapa132.png'
import insta from './media/rrss/instagramblack2.svg'
import face from './media/rrss/facebookblack2.svg'

const form2mailUrl = 'https://workfreepv.cl/php/form2mail.php'

function WorkFreeContact(){
    const [largo,updateLargo] = useState(0)

    const updateMensajeLenght = ( value) => {
        updateLargo( value.target.value.length);
    }

    const handleSubmit = () => {
        //TODO add validations
        const nombre = document.getElementById('WorkFree_Contacto_Nombre')
        if( nombre.value.length==0){
            alert('Por favor ingresa tu nombre'); //TODO cambiar a modal
            nombre.focus();
            return;
        }
        const phone = document.getElementById('WorkFree_Contacto_Telefono')
        if( phone.value.length==0){
            alert('Por favor ingresa tu teléfono'); //TODO cambiar a modal
            phone.focus();
            return;
        }
        const mail = document.getElementById('WorkFree_Contacto_Correo')
        if( mail.value.length==0){
            alert('Por favor ingresa tu correo'); //TODO cambiar a modal
            mail.focus();
            return;
        }
        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if(!mail.value.match(mailformat)){
            alert('Por favor ingresa un correo válido'); //TODO cambiar a modal
            mail.focus();
            return;
        }

        const subject = document.getElementById('WorkFree_Contacto_asunto')
        if( subject.value.length==0){
            alert('Por favor ingresa el asunto'); //TODO cambiar a modal
            subject.focus();
            return;
        }
        const message = document.getElementById('WorkFree_Contacto_Detalle')
        if( message.value.length==0){
            alert('Por favor ingresa el mensaje'); //TODO cambiar a modal
            message.focus();
            return;
        }
        const formData = {
            ['name']: nombre.value,
            ['phone']: phone.value,
            ['from']: mail.value,
            ['subject']: subject.value,
            ['message']: message.value
        };
        (async()=>{
          const response = await fetch(form2mailUrl, {
                mode: 'cors', 
                method: 'POST',
              //  headers: { 'Content-Type': 'application/json' },//TODO add to cors
                body: JSON.stringify(formData) });
              //  const data = await response.json()
                alert("Correo enviado");
       })()
      }

    return (
    <div><div>
        <Header/>
        <div className="WorkFree_contact2" >
           <div className="WorkFree_Contact_Header">¡Contáctanos!</div>
           <div className="contactoconmapa" >
               <div className='contacto_formulario'>
                       <div className="WorkFree_contact_field_label">Nombre y apellido</div>
                       <div><input type="text" id="WorkFree_Contacto_Nombre" className='contacto_field'/></div>
                       <div className="WorkFree_contact_field_label">Correo</div>
                       <div><input type="text"  id="WorkFree_Contacto_Correo" className='contacto_field'/></div>
                       <div className="WorkFree_contact_field_label">Whatsapp</div>
                       <div><input type="text"  id="WorkFree_Contacto_Telefono" className='contacto_field'/></div>
                       <div className="WorkFree_contact_field_label">Asunto</div>
                       <div><input type="text"  id="WorkFree_Contacto_asunto" className='contacto_field'/></div>
                       <div className="WorkFree_contact_field_label">Tu mensaje <span className='largomensaje'>({largo}/200)</span></div>
                       <div><textarea name="" id="WorkFree_Contacto_Detalle" cols="16" rows="5" onChange={updateMensajeLenght} maxLength="200" className='textAreaContacto'></textarea></div>
                       <button id="WorkFree_contact_button" className="WorkFree_contact_button" onClick={handleSubmit}>Enviar</button>
                   </div>
                   <div className='contacto_mapa'>
                   <img src={Mapa} className="mapaContacto"/>
               </div>
            </div>
        </div>
        <div className="ondasuperior"></div>
        <div className="WorkFreeOffice_services_social2">
             <div>
                <h2>Nos encontramos ubicados a 7 Minutos de Plaza de Puerto Varas</h2>
           </div>
                <a href="https://instagram.com/workfreepv.cl">
                    <img src={insta} alt="https://instagram.com/workfreepv.cl" width="30" height="30" className="WorkFree_RRSS"/>
                </a>
                <a href="https://www.facebook.com/workfreepv.cl/">
                    <img src={face}  alt="https://www.facebook.com/workfreepv.cl/" width="30" height="30" className="WorkFree_RRSS"/>
                </a>        
            </div>
        
        
    </div>
    <div className="ondainferior"></div>
   </div>

)}

export default WorkFreeContact