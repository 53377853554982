import React from 'react'

import Header from './header'
import Productora from './WorkFreeProducciones'

export default () =>(
    <div /** className="FondoVerde" */>
        <Header/>
        <Productora/>
    </div>
)