import React from 'react'

import WorkFreeOffice_VideoModal from './WorkFreeVideoModal'
//import WorkFreeOfficeCarousel from './carouselWorkFreeOffice'
import { useMediaQuery } from 'react-responsive'
import WorkFreeOffice_Carousel from './carousel/WorkFreeOffice_Carousel'
import insta from './media/rrss/instagramblack2.svg'
import face from './media/rrss/facebookblack2.svg'

import './CSS/workfreepv_office.css'

/*
const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
*/

function WorkFreeOffice(props){
    const isDesktop = useMediaQuery({ query: '(min-width: 1224px)' })
    return (
    <div className="WorkFreeOffice" id="WorkFree_Office">
        <div className="WorkFreeOffice_title">
            <span className="WorkFree_TitleRed">WORK</span> FREE - COWORK
        </div>
        <div className="WorkFreeOffice_slang">
            <p>Espacio integral único en el Sur de Chile, que cuenta con todos los servicios necesarios para trabajar con altos estándares de comodidad donde empresarios y emprendedores conectan sus ideas, desarrollen sus labores, amplíen su negocio y lo lleven al siguiente nivel.</p>
        </div>
        <div className="WorkFreeOffice_services">
            <div className="WorkFreeOfficeCarrousel" >
              <WorkFreeOffice_Carousel/>
            </div>
            <div className="WorkFreeOffice_services_ad">
                <WorkFreeOffice_VideoModal/>
                <div className="WorkFreeOffice_services_ad_body">
                    <div className="WorkFreeOffice_Service_Detail">-Planes a tu medida</div>
                    <div className="WorkFreeOffice_Service_Detail">-Espacios privados compartidos</div>
                    <div className="WorkFreeOffice_Service_Detail">-Salas de reuniones</div>
                    <div className="WorkFreeOffice_Service_Detail">-Coffe Free</div>
                    <div className="WorkFreeOffice_Service_Detail">-Internet dedicado para cada IP</div>
                </div>
                {isDesktop &&    
                    <div className="WorkFreeOffice_services_social_text">
                        Visita nuestras redes sociales
                    </div>
                }
                {isDesktop &&    
                    <div className="WorkFreeOffice_services_social_text">
                        Work free Office
                    </div>
                }
                <div className="WorkFreeOffice_services_social">
                    <a href="https://instagram.com/workfreepv.cl">
                        <img src={insta} alt="https://instagram.com/workfreepv.cl" width="30" height="30" className="WorkFree_RRSS"/>
                    </a>
                    <a href="https://www.facebook.com/workfreepv.cl/">
                        <img src={face}  alt="https://www.facebook.com/workfreepv.cl/" width="30" height="30" className="WorkFree_RRSS"/>
                    </a>
                </div>
            </div>
        </div>
    </div> 
)}
export default WorkFreeOffice