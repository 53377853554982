import React from 'react'
import Modal from 'react-modal'; 

///import { useMediaQuery } from 'react-responsive'

import insta from '../media/rrss/instagramblack2.svg'
import face from '../media/rrss/facebookblack2.svg'

//import video1 from '../media/video/CortoWFCorporativoyPublicitario.mp4'
//import video1poster from '../media/video/Publicitario.jpg'
//import video2 from '../media/video/CortoWFInduccionycapacitacion.mp4'
//import video2poster from '../media/video/induccioncapa.jpg'
//import video3 from '../media/video/CortoWFTvyDocu.mp4'
//import video3poster from '../media/video/tvdocu.jpg'

import './WorkFreePV_ProduccionAudiovisual_desktop.css'
import './WorkFreePV_ProduccionAudiovisual_mobile.css'

//import video1img from './image/video1.png'
//import video2img from './image/video2.png'
//import video3img from './image/video3.png'

import video1 from './video/CortoWFCorporativoPublicitario.mp4'
import video2 from './video/CortoWFInduccionCapacitacion.mp4'
import video3 from './video/CortoWFTvDocu.mp4'

import playIcon from './image/play.png'

function contactus(){
    location.href='/contacto'
}

function WorkFreePV_ProduccionAudiovisual(){
    const [modal1IsOpen, setIsOpen1] = React.useState(false)
    const [modal2IsOpen, setIsOpen2] = React.useState(false)
    const [modal3IsOpen, setIsOpen3] = React.useState(false)
    const isMobile=window.innerWidth<700;
    const customStyles =isMobile ? {
        content: {
          paddingTop:'100px',
          height: '240px'
          },
    }: {
        content: {
          paddingTop:'190px',
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          width: '70%'
        },
      };
    function openModal1() {
      setIsOpen1(true)
    }
    function openModal2() {
        setIsOpen2(true)
    }
    function openModal3() {
        setIsOpen3(true)
    }
  
    function closeModal() {
      setIsOpen1(false)
      setIsOpen2(false)
      setIsOpen3(false)
    }
    return(
        <div className='WorkFreePV_ProduccionAudiovisual'>
            <div className='WorkFreePV_ProduccionAudiovisual_header'>
              <h1 className='WorkFreePV_ProduccionAudiovisual_title'>Producción audiovisual</h1>
              <p className='WorkFreePV_ProduccionAudiovisual_content'>Sabemos lo que necesitan las nuevas empresas para posicionarse en el mundo digital y los ayudamos a través de herramientas como:</p> 
              <ul className='WorkFreePV_ProduccionAudiovisual_header_list'>
                <li>Videos publicitarios </li>
                <li>Fotografía corporativa</li>
                <li>Diseño gráfico</li>
                <li>Videos corporativos</li>
                <li>Videos testimoniales</li>
                <li>Programas de TV y documentales </li>
                <li>Videos de cursos online </li>
                <li>Producción audiovisual en general</li>
              </ul>
            </div>
            <div className='WorkFreePV_ProduccionAudiovisual_videopreview'>
                Nuestro Portafolio:
            </div>
            <div className='WorkFreePV_ProduccionAudiovisual_videos'>
                <div className='WorkFreePV_ProduccionAudiovisual_videopreview_img_1'>
                    <img src={playIcon} onClick={openModal1} alt="Reproducir primer video" className='WorkFreePV_ProduccionAudiovisual_videopreview_img_playicon'></img> 
                </div>
                <div className='WorkFreePV_ProduccionAudiovisual_videopreview_img_2'>
                    <img src={playIcon} onClick={openModal2} alt="Reproducir segundo video" className='WorkFreePV_ProduccionAudiovisual_videopreview_img_playicon'></img> 
                </div>
                <div className='WorkFreePV_ProduccionAudiovisual_videopreview_img_3'>
                    <img src={playIcon} onClick={openModal3} alt="Reproducir tercer video" className='WorkFreePV_ProduccionAudiovisual_videopreview_img_playicon'></img> 
                </div>
            </div>
            <Modal
                isOpen={modal1IsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="WorkFreePV Vídeos corporativos y publicitarios">
                        <button onClick={closeModal} className="WorkFree_CloseVideoButton">Cerrar</button>
                        <video src={video1} className='WorkFreePV_ProduccionAudiovisual_video' autoPlay="autoplay"></video>
            </Modal>
            <Modal
                isOpen={modal2IsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="WorkFreePV Videos de inducción y capacitación">
                        <button onClick={closeModal} className="WorkFree_CloseVideoButton">Cerrar</button>
                        <video src={video2} className='WorkFreePV_ProduccionAudiovisual_video' autoPlay="autoplay"></video>
            </Modal>
            <Modal
                isOpen={modal3IsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="WorkFrePV Programas de TV y documentales">
                        <button onClick={closeModal} className="WorkFree_CloseVideoButton">Cerrar</button>
                        <video src={video3} className='WorkFreePV_ProduccionAudiovisual_video' autoPlay="autoplay"></video>
            </Modal>
            <div className="WorkFreePV_audiovisual_banner">
                <div className='WorkFreePV_ProduccionAudiovisual_text_cotizar'>¿Tienes dudas?</div>
                <div className='WorkFreePV_ProduccionAudiovisual_boton_cotizar' onClick={contactus}>
                    Cotiza con nosotros
                </div>
                    
            </div>
            <div className="WorkFreePV_audiovisual_rrss" >
                <div className="centerded">Visita nuestras redes sociales - Work free Producciones</div>
                <div className="centerded">
                    <a href="https://instagram.com/workfreepv.cl">
                        <img src={insta} alt="@workfreepv" width="30" height="30" className="WorkFree_RRSS"/>
                    </a>
                    <a href="https://www.facebook.com/workfreepv.cl/">
                        <img src={face}  alt="@workfreepv" width="30" height="30" className="WorkFree_RRSS"/>
                    </a>
                </div>
            </div>
        </div>
    )
    /*
    const isMobile = useMediaQuery({ query: '(max-width: 900px)' }) //TODO define limit
    
    return (
    <div>
        <Header/>
        <div className="WorkFreeProducciones2">
          
          <div >
              <h1 className='WorkFreeProducciones_audiovisual_title'>PRODUCCIÓN AUDIOVISUAL</h1>
          </div>
           <div className="WorkFreeProducciones_block_1">
               <div>
                   <p>Sabemos lo que necesitan las nuevas empresas para posicionarse en el mundo digital y los ayudamos a través de herramientas como los videos publicitarios, fotografía corporativa, diseño gráfico, videos corporativos, videos testimoniales, videos de cursos online y producción audiovisual en general.</p>
               </div>
               <div  className="WorkFreeProducciones_block_21" >
                   <div  className="WorkFreeProducciones_block_3 ">
                       <h2>Vídeos corporativos y publicitarios</h2>
                       <p>Facilitamos la comunicación entre las empresas y su público objetivo, destacando su esencia, beneficios y ventajas a través de las imágenes y el audio.</p>
                   </div>
                   <div  className="WorkFreeProducciones_block_4" >
                       <video width="200" height="200" controls className="video1" poster={video1poster}>
                           <source src={video1} type="video/mp4"/>
                       </video>
                 </div>
               </div>
           </div>
       </div>
       <div className="ondasuperior"></div>
       <div className="WorkFreeProducciones_block_6" >
          <div className="WorkFreeProducciones_block_7">
              <div  className="WorkFreeProducciones_block_8">
                  <h2>Videos de Inducción y Capacitación</h2>
                   <p>Generamos cápsulas audiovisuales didácticas y entretenidas para capacitar a los colaboradores y proveedores de tu empresa.</p>
               </div>
              <div className="WorkFreeProducciones_block_9">
                <video width="200" height="200" controls className="video1" poster={video2poster}>
                    <source src={video2} type="video/mp4"/>
                </video>
              </div>
          </div>
          <div className="WorkFreeProducciones_block_7">
               <div  className="WorkFreeProducciones_block_8">
                   <h2>Programas de TV y Documentales</h2>
                   <p>Creamos programas audiovisuales para diferentes plataformas de acuerdo a la necesidad de nuestros clientes.</p>
               </div>
               <div className="WorkFreeProducciones_block_9">
                  <video width="200" height="200" controls className="video1" poster={video3poster}>
                        <source src={video3} type="video/mp4"/>
                    </video>
               </div>
           </div>
           {!isMobile && <div className="centerded">Visita nuestras redes sociales - Work free Producciones</div>}
           <div className="centerded">
               <a href="https://instagram.com/workfreeproducciones">
                   <img src={insta} alt="https://instagram.com/workfreeproducciones" width="30" height="30" className="WorkFree_RRSS"/>
               </a>
               <a href="https://www.facebook.com/workfreeproducciones/">
                   <img src={face}  alt="https://www.facebook.com/workfreeproducciones/" width="30" height="30" className="WorkFree_RRSS"/>
               </a>
           </div>
       </div>
       <div className="WorkFreeProducciones_block_10"></div>
    </div>
    )
    */
}

export default WorkFreePV_ProduccionAudiovisual