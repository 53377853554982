import React from 'react'


//TODO use lazy
import WorkFreeProduccionesEventos_Carousel_Desktop from './carousel_desktop'
import WorkFreeProduccionesEventos_Carousel_Mobile from './carousel_mobile'

//TODO Use global viewport
const useViewport = () => { 
    const [width, setWidth] = React.useState(window.innerWidth);
  
    React.useEffect(() => {
      const handleWindowResize = () => {
        setWidth(window.innerWidth);
      }
  
      window.addEventListener("resize", handleWindowResize);
      return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    return {width}
}

function WorkFreeProduccionesEventos_Carousel(){
    const { width } = useViewport();
    const breakpoint = 620;
  
    return width < breakpoint ? <WorkFreeProduccionesEventos_Carousel_Mobile /> : <WorkFreeProduccionesEventos_Carousel_Desktop />;
}
export default WorkFreeProduccionesEventos_Carousel