import React from 'react'
import Modal from 'react-modal'; 

//Modal.setAppElement('#WorkFree_VideoModal');
function WorkFreeOffice_VideoModal(){
    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          width: '80%'
        },
      };

      const [modalIsOpen, setIsOpen] = React.useState(false);
    
      function openModal() {
        setIsOpen(true);
      }
    
      function afterOpenModal() {
        // references are now sync'd and can be accessed.
        
      }
    
      function closeModal() {
        setIsOpen(false);
      }
      
    return(
    <div className="WorkFree_VideoModal">
        <span className="WorkFreeOffice_conocemas">¡Conoce Más! </span>
        <span  onClick={openModal} className="WorkFree_OpenVideoButton">(ver video)</span>
        <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="WorkFree Producciones">
                <button onClick={closeModal} className="WorkFree_CloseVideoButton">Cerrar</button>
                <iframe className="WorkFree_VideoIframe" width="100" height="100" src="https://www.youtube.com/embed/ue3xim9OByc" title="YouTube video player"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" ></iframe>
      </Modal>
    </div>
)}

export default WorkFreeOffice_VideoModal