import React,{ useState } from 'react'
import {Link} from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'

import Menu from './menu/Menu'
import Toggle from './menu/Toggle'

import './CSS/workfreepv_header.css'

import logo from './media/WorkFreeLogo.svg'

function WorkFreeHeader(){
    
    const [navToggled, setNavToggled] = useState(false);

    const handleNavToggle = () => {
      setNavToggled(!navToggled);
    }

    const isMobile = useMediaQuery({ query: '(max-width: 900px)' }) //TODO define limit
    const isDesktop = useMediaQuery({ query: '(min-width: 900px)' })

    return(
    <div className="WorkFree_header">
        <div className="WorkFree_logo">
            <img src={logo} className='Workfreepv_Header_logo'alt="WorkFreePV Logo" width="180" height="100"></img>
        </div>
       
     
        { isDesktop && 
         <div className="workfreepv_menu">
        <div className="WorkFree_menu">
            <Link to="/" className="WorkFree_menu_item">INICIO</Link>
            <Link to="/office" className="WorkFree_menu_item">COWORK</Link>       
            <Link to="/productora" className="WorkFree_menu_item">PRODUCTORA</Link>
            <Link to="/contacto" className="WorkFree_menu_item">CONTACTO</Link>       
        </div>
         </div>
        }
       
    </div>
    )
}

export default WorkFreeHeader
