import React from 'react'
import { Link } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'

import './CSS/workfreepv_productora.css'

import insta from './media/rrss/instagramwhite2.svg'
import face from './media/rrss/facebookwhite2.svg'

import  video from './media/video/REELWorkFreeProducciones.mp4'
import  videoPoster from './media/video/WFProduccionesportada.jpg'

function WorkFreeProductora(){

    const isDesktop = useMediaQuery({ query: '(min-width: 1224px)' })

    return (
    <div className="WorkFreeProducciones" id="WorkFree_Productora">
        <div className="WorkFreeProducciones_title">
            <h2><span className="WorkFree_TitleRed">WORK</span> FREE - PRODUCCIONES</h2>
        </div>
        <div className="productoraHeader">
            <div className="WorkFreeProducciones_slang">
                <p>Somos un equipo de profesionales multidisciplinario que nos apasiona lo que hacemos y buscamos en cada proyecto tener un rol proactivo en todo el proceso creativo, estratégico e integral de las comunicaciones, el marketing, el diseño audiovisual y la experiencia digital. Estamos comprometidos con las necesidades de nuestros clientes para ser parte de la solución.</p>
            </div>
            <div className="videoProductoraContainer">
                <video controls className="videoProductora" poster={videoPoster} >
                    <source src={video}></source>
                </video>
            </div>
        </div>
        <div className="WorkFreeProducciones_services">
            <div className="WorkFreeProducciones_services_block">
                <div id="WorkFree_Produccion" className="WorkFreeProducciones_service_box">
                    <div className="WorkFreeProducciones_service_title">
                        <h3>Producción<br/> audiovisual</h3>
                    </div>
                    <div className="WorkFreeProducciones_service_subtitle">
                        <p>Fotografía</p>
                        <p>Video</p>
                    </div>
                    <div className='WorkFreeProducciones_service_link_box'>
                        <Link to="/produccion_audiovisual" className="WorkFreeProducciones_service_link">Conoce Más</Link>  
                    </div>
                </div>
                <div id="WorkFree_Matrimonios" className="WorkFreeProducciones_service_box">
                    <div className="WorkFreeProducciones_service_title">
                        <h3 className="wFPMatrimonios">Matrimonios</h3>
                    </div>
                    <div className="WorkFreeProducciones_service_subtitle">
                        <p>Banquetería</p>
                    </div>
                    <div className="WorkFreeProducciones_service_subtitle">
                        <p>Todo incluido</p>
                    </div>
                    <div className="WorkFreeProducciones_service_subtitle">
                        <p>Flexibilidad de Pago</p>
                    </div>
                    <div className='WorkFreeProducciones_service_link_box'>
                        <Link to="/matrimonios" className="WorkFreeProducciones_service_link">Conoce Más</Link>
                    </div>
                </div>
            </div>
            <div className="WorkFreeProducciones_services_block">
            <div id="WorkFree_Eventos" className="WorkFreeProducciones_service_box">
            
                <div className="WorkFreeProducciones_service_title">
                    <h3>Eventos</h3>
                </div>
                <div className="WorkFreeProducciones_service_subtitle">
                    <p>Empresas</p>
                    <p>Instituciones</p>
                    <p>Privados</p>
                </div>
                <div className='WorkFreeProducciones_service_link_box'>
                    <Link to="/eventos"  className="WorkFreeProducciones_service_link">Conoce Más</Link>
                </div>
            </div>
            <div  id="WorkFree_Marketing" className="WorkFreeProducciones_service_box">
                <div className="WorkFreeProducciones_service_title">
                    <h3>Diseño y marketing</h3>
                </div>
                <div className="WorkFreeProducciones_service_subtitle">
                    <p>Diseño web y de apps</p>
                    <p>Marketing</p>
                    <p>Diseño Gráfico</p>
                </div>
                <div className='WorkFreeProducciones_service_link_box'>
                    <Link to="/marketing" className="WorkFreeProducciones_service_link">Conoce Más</Link>
                </div>
            </div>
            </div>
        </div>
        {isDesktop && 
            <div className="WorkFreeProducciones_services_social">
               Visita nuestras redes sociales - Work Free Producciones
            </div>
        }
        <div className="WorkFreeProducciones_services_social2">
            <a href="https://instagram.com/workfreeproducciones">
            <img src={insta} alt="https://instagram.com/workfreeproducciones" width="30" height="30" className="WorkFree_RRSS"/>
            </a>
            <a href="https://facebook.com/workfreeproducciones">
                <img src={face}  alt="https://facebook.com/workfreeproducciones" width="30" height="30" className="WorkFree_RRSS"/>
            </a>
        </div>
    </div>
)}

export default WorkFreeProductora