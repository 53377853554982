import React from 'react'

//IMAGE ASSETS
import mkt1 from './image/marketing1.jpg'
import mkt2 from './image/marketing2.jpg'
import mkt3 from './image/disenografico.jpg'
import insta from'../media/rrss/instagramblack2.svg'
import face from '../media/rrss/facebookblack2.svg'

//CSS ASSETS
import './WorkFreePV_Marketing_mobile.css'
import './WorkFreePV_Marketing_desktop.css'

/**
 *   <div className="WorkFreeProducciones3">
           <div className="WorkFreeProducciones_block_1">
               <div  className="WorkFreeProducciones_block_2" >
                   <div  className="WorkFreeProducciones_block_11 ">
                       <h2>Diseño y Desarrollo Web</h2>
                       <p>Sitios web atractivos, personalizados y responsivos</p>
                       <p>Carrito de compra</p>
                       <p>Garantía y servicio</p>
                       <p>Atención personalizada</p>
                   </div>
                   <div  className="WorkFreeProducciones_block_11" >
                       <h2>Campañas Digitales</h2>
                       <p>Posiciona tu empresa en Google</p>
                       <p>Optimiza tu tasa de conversión</p>
                       <p>Aumenta tus clientes e ingresos</p>
                       <p>Medición y análisis de efectividad de tus campañas</p>
                       <p>Mejoramiento continuo de tus campañas</p>
                 </div>
               </div>
           </div>
       </div>
       <div className="WorkFreeProducciones_block_6_row asflex">
               <div className="WorkFreeProducciones_block_12">
                   <img src={mkt1} width="180" height="180"   className="WorkFreeProducciones_block_5"/>
               </div><div className="WorkFreeProducciones_block_12">
                   <img src={mkt2} width="180" height="180"   className="WorkFreeProducciones_block_5"/>
               </div>
               <div className="WorkFreeProducciones_block_12">
                   <img src={mkt3} width="180" height="180"   className="WorkFreeProducciones_block_5"/>
               </div>
           </div>
 */
function contactus(){
    location.href='/contacto'
}
export default () => (
    <div className='WorkFreePV_Marketing'>
        <div className="WorkFreeProducciones_title_marketing" >
            Diseño y Marketing
        </div>
        <div className="WorkFreePV_marketing_subtitle">
           Te ayudamos a diseñar estrategias específicas para tu tipo de negocio, elegir los canales donde está tu público objetivo y medir en tiempo real las acciones que llevas a cabo. Así, ganas visibilidad optimizando recursos.
        </div>
        <div className='WorkFreePV_marketing_block_1'>
            <div className='WorkFreePV_marketing_block_1_image_container'>
                <img src={mkt1} alt="" className='WorkFreePV_marketing_block_1_image_container'></img>
            </div>
            <div className='WorkFreePV_marketing_block_1_content'>
                <span className='WorkFreePV_marketing_block_1_title'>Diseño y Desarrollo Web</span>
                <p className='WorkFreePV_marketing_block_1_text'>- Desarrollo de páginas web</p>
                <p className='WorkFreePV_marketing_block_1_text'>- Diseño de aplicaciones</p>
                <p className='WorkFreePV_marketing_block_1_text'>- Marketing digital</p>
                <p className='WorkFreePV_marketing_block_1_text'>- Administración de redes sociales</p>    
            </div>
            
        </div>
        <div className='WorkFreePV_marketing_block_2'>
            <div className='WorkFreePV_marketing_block_2_content'>
                <span className='WorkFreePV_marketing_block_1_title'>Campañas digitales</span>
                <p className='WorkFreePV_marketing_block_1_text'>- Posiciona tu empresa en Google</p>
                <p className='WorkFreePV_marketing_block_1_text'>- Optimiza tu tasa de conversión</p>
                <p className='WorkFreePV_marketing_block_1_text'>- Medición y análisis de efectividad de tus campañas</p>
                <p className='WorkFreePV_marketing_block_1_text'>- Mejoramiento continuo de tus campañas</p>    
            </div>
            <div className='WorkFreePV_marketing_block_1_image_container'>
                <img src={mkt2} alt="" className='WorkFreePV_marketing_block_1_image_container'></img>
            </div>
        </div>
        <div className='WorkFreePV_marketing_block_3'>
        <div className='WorkFreePV_marketing_block_1_image_container'>
                <img src={mkt3} alt="" className='WorkFreePV_marketing_block_1_image_container'></img>
            </div>
            <div className='WorkFreePV_marketing_block_1_content'>
                <span className='WorkFreePV_marketing_block_1_title'>Diseño gráfico</span>
                <p className='WorkFreePV_marketing_block_1_text'>- Diseño de logotipo e imagen corporativa</p>
                <p className='WorkFreePV_marketing_block_1_text'>- Merchandising</p>
                <p className='WorkFreePV_marketing_block_1_text'>- Brochure corporativo</p>
                <p className='WorkFreePV_marketing_block_1_text'>- Infografías</p>    
            </div>
        </div>
        <div className="WorkFreePV_marketing_banner">
            <div className='WorkFreePV_marketing_text_cotizar'>¿Tienes dudas?</div>
            <div className='WorkFreePV_marketing_boton_cotizar' onClick={contactus}>
                Cotiza con nosotros
            </div>    
        </div>
       <div className="WorkFreePV_marketing_rrss" >
           <div className="centerded">Visita nuestras redes sociales - Work free Producciones</div>
           <div className="centerded">
               <a href="https://instagram.com/workfreepv.cl">
                   <img src={insta} alt="@workfreepv" width="30" height="30" className="WorkFree_RRSS"/>
               </a>
               <a href="https://www.facebook.com/workfreepv.cl/">
                   <img src={face}  alt="@workfreepv" width="30" height="30" className="WorkFree_RRSS"/>
               </a>
           </div>
       </div>
    </div>
)