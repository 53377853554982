import React,{ Suspense } from 'react'
import Carousel from 'nuka-carousel'

//TODO use lazy load
import imgDesktop_1 from './carousel/imagen-carrusel-1.jpg'
import imgDesktop_2 from './carousel/imagen-carrusel-2.jpg'
import imgDesktop_3 from './carousel/imagen-carrusel-3.jpg'


function WorkFreeProduccionesEventos_Carousel_Desktop(){
    return(
        <Carousel
            wrapAround
            autoplay
            dragging
            swiping
            withoutControls
            pauseOnHover={false}
            slidesToShow={1}
            cellSpacing={0}
            autoplayInterval={4000}
        >
            <img src={imgDesktop_1}/>
            <img src={imgDesktop_2}/>
            <img src={imgDesktop_3}/>
        </Carousel>
    )
}
export default WorkFreeProduccionesEventos_Carousel_Desktop