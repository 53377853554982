import React,{useEffect, useState} from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

//TODO cargar imagenes para 1x y 2x
//import "./css/carousel.css"; 

const imagesListWf = require.context('./media/carousel/Mobile',false,/\.(png|jpg|svg)$/)
const imagesWf = []
const imgsWf=[]

const WorkFreeCarousel = () =>{
    const [loadingWf, setLoadingWF] = useState(null)
    
    useEffect(()=>{
        setLoadingWF(true)
        imagesListWf.keys().map((item,i) => { 
            const img = import('./media/carousel/Mobile/' +item.replace('./',''))
            .then(img=>imgsWf.push(img.default))
            imagesWf.push(img)
            
        })
        Promise.all(imagesWf).then(setLoadingWF(false))
    })
    
    if(loadingWf){
        return "Loading"
    }
 
    return (
        <>
        { imagesWf.length > 0 &&(
        <Carousel autoPlay showThumbs={false} interval={3000} infiniteLoop={true} hidden="hidden" showStatus={false} showIndicators={false} showArrows={false}>
            {imgsWf.map((image) => { 
                return(
                <div key={image}>
                    <img src={image} />
                </div>)
            })}
        </Carousel>)}
    </>
        )
}

export default WorkFreeCarousel