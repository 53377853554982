import React,{ Suspense } from 'react'
import Carousel from 'nuka-carousel'

//TODO use lazy load
import imgMobile_1 from './fotos/coworkmobil/coworkmobil1.jpg'
import imgMobile_2 from './fotos/coworkmobil/coworkmobil2.jpg'
import imgMobile_3 from './fotos/coworkmobil/coworkmobil3.jpg'
import imgMobile_4 from './fotos/coworkmobil/coworkmobil4.jpg'
import imgMobile_5 from './fotos/coworkmobil/coworkmobil5.jpg'
import imgMobile_6 from './fotos/coworkmobil/coworkmobil6.jpg'

import imgDesktop_1 from './fotos/coworkpc/coworkpc1.jpg'
import imgDesktop_2 from './fotos/coworkpc/coworkpc2.jpg'
import imgDesktop_3 from './fotos/coworkpc/coworkpc3.jpg'
import imgDesktop_4 from './fotos/coworkpc/coworkpc4.jpg'
import imgDesktop_5 from './fotos/coworkpc/coworkpc5.jpg'
import imgDesktop_6 from './fotos/coworkpc/coworkpc6.jpg'

const useViewport = () => { //TODO add global context
    const [width, setWidth] = React.useState(window.innerWidth);
    // Add a second state variable "height" and default it to the current window height
    const [height, setHeight] = React.useState(window.innerHeight);
  
    React.useEffect(() => {
      const handleWindowResize = () => {
        setWidth(window.innerWidth);
        // Set the height in state as well as the width
        setHeight(window.innerHeight);
      }
  
      window.addEventListener("resize", handleWindowResize);
      return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    return {width,height}
}
    
function WorkFreeOffice_Carousel_Mobile(){
    
    return(
        <Suspense fallback={<div>Loading...</div>}>
        <Carousel
            wrapAround
            autoplay
            dragging
            swiping
            withoutControls
            pauseOnHover={false}
            slidesToShow={1}
            autoplayInterval={4000}
        >
            <img src={imgMobile_1}/>
            <img src={imgMobile_2}/>
            <img src={imgMobile_3}/>
            <img src={imgMobile_4}/>
            <img src={imgMobile_5}/>
            <img src={imgMobile_6}/>
        </Carousel>
        </Suspense>
    )
}

function WorkFreeOffice_Carousel_Desktop(){
    return(
        <Carousel
            wrapAround
            autoplay
            dragging
            swiping
            withoutControls
            pauseOnHover={false}
            slidesToShow={2}
            cellSpacing={0}
            autoplayInterval={4000}
        >
            <img src={imgDesktop_1}/>
            <img src={imgDesktop_2}/>
            <img src={imgDesktop_3}/>
            <img src={imgDesktop_4}/>
            <img src={imgDesktop_5}/>
            <img src={imgDesktop_6}/>
        </Carousel>
    )
}

function WorkFreeOffice_Carousel(){
    const { width } = useViewport();
    const breakpoint = 620;
  
    return width < breakpoint ? <WorkFreeOffice_Carousel_Mobile /> : <WorkFreeOffice_Carousel_Desktop />;
}
export default WorkFreeOffice_Carousel