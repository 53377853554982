import React from 'react'

import Header from './header'
import Office from './workFreeOffice'

export default () =>(
    <div className="">
        <Header/>
        <Office/>
    </div>
)