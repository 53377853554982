import React,{ Suspense } from 'react'
import Carousel from 'nuka-carousel'

//TODO use lazy load
import imgMobile_1 from './fotos/indexmobil/carruselmobil1.jpg'
import imgMobile_2 from './fotos/indexmobil/carruselmobil2.jpg'
import imgMobile_3 from './fotos/indexmobil/carruselmobil3.jpg'
import imgMobile_4 from './fotos/indexmobil/carruselmobil4.jpg'
import imgMobile_5 from './fotos/indexmobil/carruselmobil5.jpg'
import imgMobile_6 from './fotos/indexmobil/carruselmobil6.jpg'
import imgMobile_7 from './fotos/indexmobil/carruselmobil7.jpg'
import imgMobile_8 from './fotos/indexmobil/carruselmobil8.jpg'
import imgMobile_9 from './fotos/indexmobil/carruselmobil9.jpg'

import imgDesktop_1 from './fotos/indexpc/carruselpc1.jpg'
import imgDesktop_2 from './fotos/indexpc/carruselpc2.jpg'
import imgDesktop_3 from './fotos/indexpc/carruselpc3.jpg'
import imgDesktop_4 from './fotos/indexpc/carruselpc4.jpg'
import imgDesktop_5 from './fotos/indexpc/carruselpc5.jpg'
import imgDesktop_6 from './fotos/indexpc/carruselpc6.jpg'
import imgDesktop_7 from './fotos/indexpc/carruselpc7.jpg'
import imgDesktop_8 from './fotos/indexpc/carruselpc8.jpg'
import imgDesktop_9 from './fotos/indexpc/carruselpc9.jpg'

const useViewport = () => { //TODO add global context
    const [width, setWidth] = React.useState(window.innerWidth);
    // Add a second state variable "height" and default it to the current window height
    const [height, setHeight] = React.useState(window.innerHeight);
  
    React.useEffect(() => {
      const handleWindowResize = () => {
        setWidth(window.innerWidth);
        // Set the height in state as well as the width
        setHeight(window.innerHeight);
      }
  
      window.addEventListener("resize", handleWindowResize);
      return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    return {width,height}
}
    
function WorkFreeHolding_Carousel_Mobile(){
    return(
        <Suspense fallback={<div>Loading...</div>}>
        <Carousel
            wrapAround
            autoplay
            dragging
            swiping
            withoutControls
            pauseOnHover={false}
            slidesToShow={1}
            
        >
            <img src={imgMobile_1}/>
            <img src={imgMobile_2}/>
            <img src={imgMobile_3}/>
            <img src={imgMobile_4}/>
            <img src={imgMobile_5}/>
            <img src={imgMobile_6}/>
            <img src={imgMobile_7}/>
            <img src={imgMobile_8}/>
            <img src={imgMobile_9}/>
        </Carousel>
        </Suspense>
    )
}

function WorkFreeHolding_Carousel_Desktop(){
    return(
        <Carousel
            wrapAround
            autoplay
            dragging
            swiping
            withoutControls
            pauseOnHover={false}
            slidesToShow={3}
            
        >
            <img src={imgDesktop_1}/>
            <img src={imgDesktop_2}/>
            <img src={imgDesktop_3}/>
            <img src={imgDesktop_4}/>
            <img src={imgDesktop_5}/>
            <img src={imgDesktop_6}/>
            <img src={imgDesktop_7}/>
            <img src={imgDesktop_8}/>
            <img src={imgDesktop_9}/>
        </Carousel>
    )
}

function WorkFreeHolding_Carousel(){
    const { width } = useViewport();
    const breakpoint = 620;
  
    return width < breakpoint ? <WorkFreeHolding_Carousel_Mobile /> : <WorkFreeHolding_Carousel_Desktop />;
}
export default WorkFreeHolding_Carousel