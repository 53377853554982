import React from 'react'
import CarouselWorkFree from './carouselWorkFree'

import WorkFreeHoldingCarousel from './carousel/WorkFreeHolding_Carousel'


function WorkFreeHolding_old(){
    return(
    <div className="WorkFreeHolding">
        <div className="WorkFreeHoldingCarousel">
            <CarouselWorkFree/> 
        </div>
        <div className="WorkFree_slang">
            <p>Somos un grupo dedicado a producir eventos, matrimonios, campañas de marketing digital y producción audiovisual.
                <br/>Contamos con un equipo multidisciplinario de profesionales y aplicamos tecnología de última generación a tus proyectos.
                <br/>También ofrecemos un Cowork con infraestructura de primer nivel donde trabajar y generar redes de negocios.</p>
        </div>
    </div>
    )
}

function WorkFreeHolding(){
    return(
        <div className="WorkFreeHolding">
        <div className="WorkFreeHoldingCarousel">
            <WorkFreeHoldingCarousel/> 
        </div>
        <div className="WorkFree_slang">
            <p>Somos un grupo dedicado a producir eventos, matrimonios, campañas de marketing digital y producción audiovisual.
                <br/>Contamos con un equipo multidisciplinario de profesionales y aplicamos tecnología de última generación a tus proyectos.
                <br/>También ofrecemos un Cowork con infraestructura de primer nivel donde trabajar y generar redes de negocios.</p>
        </div>
    </div>
    )
}

export default WorkFreeHolding