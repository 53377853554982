import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { useLocation } from 'react-router-dom'

function WorkFreeFooter(){

    const isDesktop = useMediaQuery({ query: '(min-width: 600px)' }) //TODO define break
    const location = useLocation()
    const isSitemap = location.pathname=='/sitemap' ? 1:0
    const classname = isSitemap ? "Workfree_footer superpad" : "Workfree_footer"
    return(
            <div className={classname}>
            {isDesktop &&
                <div className="Workfree_footer_logo"></div>
            }
           
            <div className="Workfree_footer_location">
                <div className="Workfree_footer_location_title">
                    <h3>Visitanos</h3>
                </div>
                <div className="Workfree_footer_location_body">
                    <p>Decher #440 , Puerto Varas</p>
                    <p>+56 9 5489 9265</p>
                    <p>info@workfreepv.cl</p>
                </div>
            </div>
            <div className="Workfree_footer_hours">
                <div className="Workfree_footer_hours_title">
                    <h3>Horario</h3>
                </div>
                <div className="Workfree_footer_hours_body">
                    <p>Lunes - Viernes</p>
                    <p>08:30 AM - 19:00 PM</p>
                </div>
            </div>
            {!isDesktop &&
                <div className="Workfree_footer_logo"></div>
            }
        </div>
    )
}

export default WorkFreeFooter