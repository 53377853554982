import React, {useState} from 'react'
import {Routes, Route} from 'react-router-dom'

import Home from './home';
import Audiovisual from './audiovisual/WorkFreePV_ProduccionAudiovisual'
import Matrimonios from './matrimonios/WorkFreeProducciones_Matrimonios'
import Marketing from './marketing/WorkFreePV_Marketing'
import Eventos from './eventos/eventos'
import Contacto from './contacto'
import Productora from './productora';
import Office from './office'
import Menu from './menu/Menu'
import "./CSS/workfreepv_all.css" //TODO Load dinamicly
import "./CSS/workfreepv_desktop.css" //TODO Load dinamicly
import "./CSS/workfreepv_mobile.css" //TODO Load dinamicly


function WorkFreePv(){    
    return (
    <Routes>
        <Route index path="/" element={<Home/>}/>
        <Route path="produccion_audiovisual" element={<Audiovisual/>}/>
        <Route path="matrimonios" element={<Matrimonios/>}/>
        <Route path="marketing" element={<Marketing/>}/>
        <Route path="eventos" element={<Eventos/>}/>
        <Route path="contacto" element={<Contacto/>}/>
        <Route path="productora" element={<Productora/>}/>
        <Route path="office" element={<Office/>}/>
        <Route path="sitemap" element={<Menu/>}/>
    </Routes>
    )
}

export default WorkFreePv